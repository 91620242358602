:root {
  --textColor: #000;
}
:root {
  --spacePart: 10px;
}
@media (max-width: 1023px) {
  :root {
    --spacePart: 10px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 10px;
  }
}
:root {
  --spaceUnit: 20px;
}
@media (max-width: 1023px) {
  :root {
    --spaceUnit: 20px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 20px;
  }
}
:root {
  --spaceTotal: 30px;
}
@media (max-width: 1023px) {
  :root {
    --spaceTotal: 30px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 30px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000;
  line-height: 1.375;
  cursor: pointer;
  color: #fff;
  padding: 28px 55px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  border: 3px solid #000;
  font-weight: bold;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.button:hover,
.button:focus {
  color: #000;
  background-color: #FFCD00;
}
.button:active {
  background-color: #FFB71B;
}
/*# sourceMappingURL=./screen-medium.css.map */